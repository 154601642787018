<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>OFFICE</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>
        <custom-vue-table-card title="" class="c-cms-table d-sm-none-lg-block" :show-search-box="false" :fields="fields"
                               :url="listUrl" :per-page="10" ref="table" :extra-params="{ext_search}">
            <template #location_image="{rowData}">
                <div class="container-image fl-x-cc pos-r" @click="viewFile(rowData.location_image)">
                    <img :src="rowData.location_image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table-card>

        <modal title="Add Office" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
            <add-office @success="formSuccess"/>
        </modal>

        <modal title="Edit Office" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
            <edit-office :initial-data="editingItem" @success="formSuccess"/>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the office <b v-html="deletingItem && deletingItem.name"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
        <office-listing-mobile-view :ext_search="ext_search" @viewDetails="viewDetails" @setDelete="setDelete"
                                    class="d-lg-none" ref="officeMobileView"></office-listing-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddOffice from './AddOffice';
import EditOffice from './EditOffice';
import OfficeListingMobileView from '@/views/cms/contact-us/office/OfficeListingMobileView';

export default {
    name       : 'Office',
    components : { OfficeListingMobileView, EditOffice, AddOffice },
    data () {
        return {
            ext_search   : '',
            listUrl      : urls.cms.office.list,
            addUrl       : urls.cms.office.addEdit,
            deleteUrl    : urls.cms.office.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'office_name',
                    sortField : 'office_name',
                    title     : 'Name'
                },
                {
                    name  : '__slot:location_image',
                    title : 'Location Image'
                },
                {
                    name      : 'office_type',
                    sortField : 'office_type',
                    title     : 'Company Name'
                },
                {
                    name      : 'contact_number',
                    sortField : 'contact_number',
                    title     : 'Contact Number'
                },
                {
                    name      : 'email_id',
                    sortField : 'email_id',
                    title     : 'E-Mail ID'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/office/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();
            refs.officeMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            console.log('item', item);
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
            this.$refs.officeMobileView.loadData();
        }
    }
};
</script>

<style scoped>

</style>
